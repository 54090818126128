:root {
  --gallery-height: 36rem;
}

.plugin--gallery {
  --gap: 80px;

  position: relative;
  overflow: hidden;

  height: var(--gallery-height);

  @media (--media-md) {
    &::after {
      content: " ";

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      z-index: var(--z-gallery-gradient);
      pointer-events: none;

      background: linear-gradient(
        to right,
        var(--accent-color),
        rgba(0, 0, 0, 0) 10% 90%,
        var(--accent-color)
      );
    }
  }

  .gallery__images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    gap: var(--gap);

    figure {
      display: flex;
      flex-direction: column;
      gap: var(--p);
      width: var(--c10);
      max-width: 100%;
      height: 100%;
    }

    .figure__meta {
      display: flex;
      padding: 0 var(--spacing-s);
    }

    .caption {
      flex: 1 0 calc(100% - 4rem);
    }
    .counter {
      flex: 1 0 4rem;
    }

    img {
      max-height: calc(var(--gallery-height) - 4rem);
      margin: auto;
    }

    .gallery__image {
      max-width: 100%;
      flex-shrink: 0;
    }

    .img__wrapper {
      height: 100%;
      display: flex;
    }
  }

  .gallery__control {
    width: 100%;
    max-width: var(--w-content);
    margin-left: auto;
    margin-right: auto;

    position: relative;

    &--next,
    &--previous {
      font-size: 1rem;
      @media (--media-md) {
        font-size: unset;
      }

      position: absolute;
      top: calc((var(--gallery-height) - 0.5em) / 2);
      z-index: var(--z-gallery-control);
    }

    &--next {
      right: var(--spacing-s);

      svg {
        transform: translateX(0.15em);
      }
    }

    &--previous {
      left: var(--spacing-s);
      svg {
        transform: translateX(-0.15em);
      }
    }
  }
}
