body::before {
  content: " ";
  height: 2rem;
}

header {
  position: sticky;
  top: 0;

  z-index: var(--z-header);
  background-color: var(--accent-color);
}

.header-container,
.menu-header-container {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
}

.header-container {
  position: relative;
  justify-content: space-between;
  gap: var(--grid-gap);
  height: 6rem;

  > .separator {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    @media (--media-md) {
      width: calc(100% - (2 * var(--p)));
      left: unset;
    }
    border-bottom: 1px solid var(--fill-color);
  }
}

.menu-header-container {
  justify-content: right;
}

.menu-is-open,
.modal-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;

  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: calc(100vh + 2rem);
  overflow-y: auto;

  padding-top: var(--margin-m);
  padding-bottom: var(--margin-xl);

  /* background: linear-gradient(var(--accent-color) 90%, rgba(0, 0, 0, 0)); */
  background-color: var(--neutral);
  color: var(--black);
  z-index: var(--z-mobile-menu);

  .nav-toggle {
    margin-left: auto;
  }

  h2 {
    margin-top: var(--margin);
  }
}

.menu-is-open .mobile-menu {
  display: unset;
  opacity: 0;
  animation: open-menu 0.3s ease-in-out both;
}

.menu-is-closed .mobile-menu {
  display: unset;
  opacity: 1;
  animation: close-menu 0.3s ease-in-out both;
}

.menu-is-closed.menu-is-hidden .mobile-menu {
  display: none;
}

.nav-list {
  display: flex;
  gap: 1em;
  /* align-items: center; */
  flex-wrap: wrap;
}

.language-nav {
  font-size: var(--interact-size);
  padding-bottom: var(--margin);

  .nav-list {
    margin-top: 2rem;
    /* justify-content: center; */
  }
  .nav-list-item {
    padding: 0.5em 0;
  }
}

.mobile-nav {
  font-size: 1.33rem;
}

.nav-link {
  color: inherit;
  padding: 0.5em 0;
}

.nav-list-item {
  &:hover,
  &:focus-visible {
    a.nav-link {
      text-decoration: underline;
    }
  }
  &.active {
    font-weight: bold;
  }
}

.logo-container {
  display: block;
  height: 100%;
}
.wordmark {
  height: 100%;
  padding: 1rem 0;
  svg {
    display: block;
    max-width: 100%;
    height: 100%;
    fill: var(--fill-color);
  }
}

@keyframes open-menu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes close-menu {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
