:root {
  --grid-gap: var(--p);
  --grid-cell: var(--c);

  /* --grid-cols: 1;
  @media (--media-md) {
    --grid-cols: 2;
  }
  @media (--media-lg) {
    --grid-cols: 3;
  } */
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-cell), 1fr));
  gap: var(--grid-gap);
}

/* .fixed-grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-cols), minmax(0, 1fr));
  gap: var(--grid-gap);
} */
