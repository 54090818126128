.boxed {
  box-shadow: 1.5625rem 1.5625rem 0px rgba(21, 21, 21, 0.35);
  .inverted:not(.modal-is-open) & {
    box-shadow: 1.5625rem 1.5625rem 0px rgba(255, 255, 255, 0.35);
  }
}

.plugin {
  max-width: var(--w-content);
  margin-top: var(--margin-l);
  margin-bottom: var(--margin-l);
}

.plugin--form {
  max-width: var(--w-prose);
  padding: var(--margin) var(--margin-m);
  background-color: var(--sun);
  color: var(--black);
  --button-color: var(--sun);

  p {
    margin: var(--spacing) 0;
    &.help {
      margin: var(--spacing-s) 0;
      font-size: small;
    }
  }

  h1 {
    margin-top: 0 !important;
    margin-bottom: var(--spacing);

    font-weight: normal;
    font-size: 2rem;
    @media (--media-md) {
      font-size: 3.5rem;
    }
  }
}

.plugin--button {
  max-width: var(--w);
  margin-top: var(--margin);
  scroll-margin: var(--margin-xl);

  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  .button {
    margin-bottom: 0;
  }

  .button-wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .indent {
    &--12 {
      max-width: var(--w);
    }
    &--10 {
      max-width: var(--w-content);
    }
    &--8 {
      max-width: var(--w-prose);
    }
  }
}

.plugin--embedded-video {
  .embedded-video__container {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    background-color: var(--white);

    &::after {
      content: attr(data-error);
      display: block;
      width: 100%;
      text-align: center;
      margin-top: var(--margin);
      color: var(--black);
    }
  }

  .embedded-video__container > iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .caption {
    margin-top: var(--margin);

    max-width: var(--c5);
    padding: 0 var(--p);
  }
}
