footer {
  padding-top: var(--margin);
  padding-bottom: var(--margin-l);
}

footer:not(.reduced) {
  background-color: var(--white);
  color: var(--black);
  --icon-color: var(--black);
  z-index: var(--z-footer);
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.25);
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  /* .nav-container {
    flex: 0 1 var(--c3);
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
  } */

  a.active {
    font-weight: bold;
  }
}

/* .contact__info {
  flex: 1 1 var(--c6);
  margin-right: auto;
}

.contact__heading {
  flex: 0 1 var(--c3);
} */

/* .footer-nav {
  flex: 1 0;

  margin-bottom: var(--margin);

  .nav-list {
    flex-direction: column;
    align-items: baseline;
  }
} */

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-bottom: var(--margin);
  gap: 1em;

  margin-left: auto;
}

.some-links-container {
  display: flex;
  gap: 1em;
}

.some-links-container,
.supporters__headline,
.supporters__grid {
  flex: 1 0 100%;
}

.footer__heading {
  font-size: 2.125rem;
  line-height: 0.75;
  margin-bottom: var(--gap);
}

.supporters__grid {
  --grid-cell: var(--c2);
}

.supporters__grid__item {
  margin: auto;
}

.meta-nav {
  margin: var(--margin-m) auto 0;
  font-size: 1.33rem;
}
