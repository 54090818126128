#edit-tools {
  position: fixed;
  bottom: 0.5em;
  left: 0.5em;
  background: #fff;
  color: #000;
  padding: 0.5em;
  border-radius: 0.5em;
  font-size: 0.7em;
  opacity: 0.7;
  z-index: 99;
}
