:root {
  --white: #ffffff;
  --black: #08080d;
  --gray: #404060;
  --gray-opaque: hsla(0, 0, 8%, 50%);

  --carmine: hsla(4, 50%, 52%, 1);
  --nevada: hsla(202, 10%, 43%, 1);
  --brown: hsla(25, 66%, 40%, 1);
  --astronaut: hsla(219, 52%, 34%, 1);
  --firebrick: hsla(360, 64%, 42%, 1);
  --astral: hsla(198, 52%, 41%, 1);
  --costa-del-sol: hsla(59, 38%, 26%, 1);
  --merlot: hsla(360, 54%, 35%, 1);
  --green: hsla(93, 29%, 39%, 1);
  --blue: hsla(235, 44%, 50%, 1);
  --eucalyptus: hsla(150, 58%, 33%, 1);

  --sun: hsla(49, 90%, 60%, 1);

  --neutral: hsla(49, 79%, 86%, 1);

  --accent-color: var(--neutral);
  --font-color: var(--black);

  --shadow: hsla(0, 0%, 8%, 0.35);
  --shadow-dark: hsla(0, 0%, 8%, 0.8);
}

html {
  background-color: var(--accent-color);
  color: var(--font-color);
  --fill-color: var(--font-color);
}
body {
  opacity: 0;
  animation: fade-in 0.3s 0.3s ease-in forwards;
}

.accent--merlot {
  --accent-color: var(--merlot);
}
.accent--carmine {
  --accent-color: var(--carmine);
}
.accent--brown {
  --accent-color: var(--brown);
}
.accent--costa-del-sol {
  --accent-color: var(--costa-del-sol);
}
.accent--green {
  --accent-color: var(--green);
}
.accent--eucalyptus {
  --accent-color: var(--eucalyptus);
}
.accent--astral {
  --accent-color: var(--astral);
}
.accent--nevada {
  --accent-color: var(--nevada);
}
.accent--blue {
  --accent-color: var(--blue);
}
.accent--astronaut {
  --accent-color: var(--astronaut);
}
.accent--firebrick {
  --accent-color: var(--firebrick);
}
