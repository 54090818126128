html {
  font-size: clamp(12px, 0.9vw, 16px);
}

body {
  font-size: 1.625rem;
}

.container {
  max-width: var(--w);
  padding: 0 var(--p);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

main {
  flex: 1 1 100%;
}

/* main > .container:first-child,
main > .container:first-child > h1:first-of-type,
.hero-container + .container {
  margin-top: var(--margin-m);
} */

main > .container:last-child {
  margin-bottom: var(--margin-m);
}

.container > .container {
  --p: 0;
}

.container {
  &.maxed {
    padding: 0;
    max-width: var(--w-max);
  }
  &.sm--full {
    padding: 0;
    @media (--media-md) {
      padding: 0 var(--p);
    }
  }
  &.md--full {
    padding: 0;
    @media (--media-lg) {
      padding: 0 var(--p);
    }
  }
}
