/* Generated by ParaType (http://www.paratype.com)*/
/* Font Futura PT: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: Futura-PT;
  font-weight: normal;
  font-style: normal;
  src:
    local("Futura-PT"),
    url(../fonts/Futura-PT_Medium.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Futura-PT;
  font-weight: bold;
  font-style: normal;
  src:
    local("Futura-PT"),
    url(../fonts/Futura-PT_Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Vollkorn;
  font-weight: normal;
  font-style: normal;
  src:
    local("Vollkorn"),
    url(../fonts/Vollkorn-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Vollkorn;
  font-weight: bold;
  font-style: normal;
  src:
    local("Vollkorn"),
    url(../fonts/Vollkorn-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Vollkorn;
  font-weight: normal;
  font-style: italic;
  src:
    local("Vollkorn"),
    url(../fonts/Vollkorn-Italic.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Vollkorn;
  font-weight: bold;
  font-style: italic;
  src:
    local("Vollkorn"),
    url(../fonts/Vollkorn-BoldItalic.woff2) format("woff2");
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Futura-PT, Helvetica, Arial, sans-serif;
  line-height: 1.4;
}

a:where([class]) {
  text-decoration: none;
  color: inherit;
}

.prose {
  & > * {
    max-width: var(--w-prose);
  }

  h1,
  h2,
  h3 {
    margin: var(--margin-l) auto var(--margin) 0;
    max-width: var(--w-content);

    line-height: 1.2;
  }

  p,
  .button,
  figure {
    margin-bottom: var(--margin);
  }

  p {
    margin-left: auto;
    margin-right: auto;
  }

  .lead {
    font-family: Futura-PT, Helvetica, Arial, sans-serif;
    margin-left: 0;
    margin-bottom: var(--margin-m);
    font-size: 2.375rem;
    line-height: 1.26;
  }

  ul {
    font-family: Vollkorn, Georgia, Times, serif;
    margin: 0 auto var(--margin);
  }
}

.prose a:not([class]),
.caption a {
  color: inherit;
  font-style: italic;
  font-weight: bold;
}

.sans,
.sans * {
  font-family: Futura-PT, Helvetica, Arial, sans-serif;
}

.caption,
small,
.small,
figure .caption p {
  font-family: Futura-PT, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

h1 {
  font-size: 3.5rem;
  @media (--media-md) {
    font-size: 5rem;
  }
  font-weight: bold;
}

h2 {
  font-size: 3.5rem;
  @media (--media-md) {
    font-size: 5rem;
  }
  font-weight: normal;
}

h3 {
  font-weight: normal;
  font-size: 2rem;
  @media (--media-md) {
    font-size: 3.5rem;
  }
}

p {
  font-family: Vollkorn, Georgia, Times, serif;
}

.serif,
.serif * {
  font-family: Vollkorn, Georgia, Times, serif;
}
