.issue-nav {
  max-width: var(--w);

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  .button {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: clamp(10px, 6vw, 1em);
    }
  }

  margin-bottom: var(--margin);
}

.issue-nav--aside {
  position: relative;
  --accent-color: var(--astral);
  &__link {
    width: 100%;
    height: 3.5rem;
    transition: height var(--screening-animation-time) ease-in-out;
    justify-content: flex-end;

    @media (--media-md) {
      position: fixed;
      top: 20%;
      height: 50%;
      width: 3rem;
      transition: width var(--screening-animation-time) ease-in-out;
      justify-content: center;
    }

    @media (--media-lg) {
      top: 0;
      height: 100%;
    }

    display: flex;
    align-items: center;
    background-color: var(--accent-color);
    z-index: var(--z-issue-nav);

    box-shadow: -2px 0px 7px rgba(0, 0, 0, 0.25);

    &__text {
      transform-origin: 50%;
      white-space: nowrap;
      font-weight: bold;
      font-size: clamp(
        var(--interact-size),
        5.76vw,
        calc(1.5 * var(--interact-size))
      );
    }

    &--next {
      @media (--media-md) {
        span {
          transform: rotate(-90deg);
          backface-visibility: hidden;
        }
        right: 0;
      }
    }
    /* &--previous {
      span {
        transform: rotate(-90deg);
      }
      left: 0;
    } */

    &.animate {
      position: absolute;
      height: calc(100vh + 8rem);
      animation: mobile-issue-menu-expand var(--screening-animation-time)
        ease-in-out forwards;
      @media (--media-md) {
        animation: unset;
        position: fixed;
        height: 100%;
      }

      top: 0;
      width: 100%;
      z-index: var(--z-issue-nav-animation);
      span {
        color: var(--accent-color);
      }
    }
  }

  .menu-is-open & {
    display: none;
  }
}

.issue-nav__icon {
  --icon-size: 1.2em;
  margin: 0 var(--p);
  @media (--media-md) {
    display: none;
  }
  .animate & {
    display: none;
  }
}

@keyframes mobile-issue-menu-expand {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-8rem);
  }
}
