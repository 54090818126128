:root {
  --button-color: var(--white);
  --button-bg: var(--black);
}

.button,
.btn {
  --accent-color: var(--neutral);
  font-size: var(--interact-size);

  &--large {
    font-size: calc(1.5 * var(--interact-size));
    font-weight: bold;
  }

  -webkit-appearance: none; /* iOS fix */

  display: inline-flex;
  align-items: center;
  gap: 0.3em;
  border-radius: 0.1em;
  padding: 0.3em 0.6em;
  cursor: pointer;

  text-decoration: none;

  color: var(--button-color);
  a& {
    color: var(--button-color);
  }

  background: var(--button-bg);
  box-shadow: 0.2em 0.2em 0px 0px var(--shadow);
  border: none;

  .icon {
    --fill-color: var(--button-color);
  }

  &--control {
    /* --icon-size: var(--interact-size); */
    font-size: calc(1.33 * var(--interact-size));

    --button-color: var(--white);
    --button-bg: var(--black);
    border-radius: 50%;
    padding: 0.3em;
    box-shadow: 2px 2px 0px var(--shadow);
  }

  &--inverted {
    --button-color: var(--black);
    --icon-color: var(--black);

    --button-bg: var(--sun);
  }

  &--arrow,
  &--download {
    --icon-size: 1.2em;
    .icon {
      transform: translateX(0.2em);
    }
  }

  &--arrow-back {
    --icon-size: 1.2em;
    .icon {
      transform: translateX(-0.2em);
    }
  }

  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    &.hover:not(.active) {
      background-color: var(--accent-color);
    }
  }

  &.active {
    --button-color: var(--black);
    --icon-color: var(--black);
    --button-bg: var(--white);
  }

  &--clean {
    background: none;
    color: unset;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
  }
}
