main::after {
  content: " ";
  display: block;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: max(120px, 9.5rem);
  background: linear-gradient(rgba(0, 0, 0, 0), var(--accent-color));
  pointer-events: none;
  z-index: var(--z-main-gradient);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
