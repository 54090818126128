:root {
  --map-size: calc(var(--c8));
}

.map {
  --z-map-marker: 1;
  --z-map-marker-active: 10;
  --z-map-marker-content: 15;
  --z-map-cluster: 3;
  --z-map-cluster-span: 5;

  position: relative;
  width: var(--map-size);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  &__container {
    height: calc(var(--map-size) / 3 * 2);
  }
}

.map__marker__visual,
.map__cluster__visual {
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
}

.map__cluster__visual {
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: var(--z-map-cluster);

  span {
    font-size: 1.325rem;
    color: var(--sun);
    z-index: var(--z-map-cluster-span);
  }
}

.map__marker__visual {
  border: 0.1em solid var(--black);
  background-color: var(--sun);

  box-shadow: 0.08em 0.08em 0px var(--shadow);

  .active > & {
    border-width: 0.2em;
    width: 1.5em;
    height: 1.5em;
    background-color: unset;
  }

  &:hover {
    cursor: pointer;
  }
}

.map__controls {
  --grid-gap: 1em;
  position: absolute;
  bottom: 1em;
  right: 1em;

  display: flex;
  gap: var(--grid-gap);

  .button {
    border-radius: 50%;
    padding: 0.75em;

    --button-bg: var(--sun);
    --button-color: var(--black);
    --icon-size: 1.75rem;
  }
}
.map__marker {
  position: relative;
  z-index: var(--z-map-marker);
  &.active {
    z-index: var(--z-map-marker-active);
  }
}
.map__marker__content {
  position: absolute;
  display: none;

  font-size: 1.2em;

  left: 120%;
  top: calc(50% - 1em);

  width: max-content;
  max-width: var(--c3);
  background-color: var(--shadow-dark);
  padding: 0.25em 0.5em;
  color: var(--sun);

  z-index: var(--z-map-marker-content);
  .active > & {
    display: block;
  }
}

.map__caption {
  max-width: var(--map-size);
  margin: var(--margin) auto 0 auto;
  padding: 0 var(--p);
}
