.persons__teaser {
  margin-bottom: var(--margin);
  &.auto-grid {
    --grid-cell: var(--c2);
  }
}

.persons__list {
  max-width: var(--c-outer);

  &.auto-grid {
    --grid-cell: var(--c3);
  }

  &__item {
    display: flex;
    flex-flow: nowrap column;
    gap: var(--grid-gap);

    margin-bottom: var(--margin-m);
  }

  &__name {
    font-size: 1.625rem;
  }

  p {
    font-size: 1.5rem;
  }

  &__info {
    a {
      color: var(--black);
    }
  }
}

.persons__teaser__item {
  position: relative;
  &__name {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    display: flex;
    align-items: flex-end;
    height: 5rem;

    span {
      color: var(--white);
      font-size: smaller;
      padding: var(--spacing-s) calc(1.5 * var(--spacing-s));
    }
  }
  &:hover,
  &:focus-visible {
    .persons__teaser__item__name {
      opacity: 1;
    }
  }
}
