.ce {
  &-image__container {
    margin-top: var(--margin-l);
    margin-bottom: var(--margin-l);
    .img__wrapper {
      max-width: var(--c10);
      margin-left: auto;
      margin-right: auto;
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.ce-image__container--with-caption {
  margin-top: var(--margin-m);
  margin-bottom: var(--margin-m);
  figure {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: var(--gap);
  }
  .img__wrapper {
    max-width: var(--c8);
    margin-left: 0;
    margin-right: 0;
  }
  .caption {
    max-width: var(--c5);
    padding: 0 var(--p);
    flex-basis: 100%;
  }
}
