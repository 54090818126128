.icon {
  width: var(--icon-size);
  height: var(--icon-size);

  fill: var(--fill-color);
}

.button-icon {
  --icon-size: 1em;
  transform: translateX(0.33em);
}

/* resets */
#icon-fullscreen,
#icon-fullscreen-exit {
  transform-origin: 50%;
  transform: scale(1.5);
}
