.bi-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 100%;
  width: 100%;

  overflow: auto;

  background-color: var(--neutral);
  color: var(--black);

  z-index: var(--z-modal);

  display: none;
  opacity: 0;

  &.closed {
    display: none;
  }

  &.open {
    display: unset;
    animation: fade-in 0.3s ease-in forwards;
    &.closing {
      opacity: 1;
      animation: fade-out 0.3s ease-in forwards;
    }
  }

  &__content {
    margin-bottom: var(--margin-xl);

    h1 {
      margin-top: var(--margin);
    }
  }
}

.bi-page-control__container {
  position: sticky;
  display: flex;
  top: 1rem;
  margin-top: var(--margin);
}

.bi-page-control {
  margin-left: auto;
}
